<template>
  <v-layout column>
    <RolesTable ref="rolesTable"></RolesTable>
    <AddDeleteButtons
      :selected="selected"
      add-icon="user-plus"
      remove-icon="user-minus"
      :buttons-text="$t('roles.role')"
      @create="onCreateClick"
      @confirm-remove="onRemoveConfirmed"
    />
  </v-layout>
</template>
<script>
import RolesTable from "../components/RolesTable";
import AddDeleteButtons from "../components/AddDeleteButtons";
import { EmitSuccess } from "../event-bus";
import RolesService from "../services/RolesService";
import { mapState } from "vuex";
export default {
  name: "Roles",
  components: { AddDeleteButtons, RolesTable },
  computed: {
    ...mapState({
      selected: state => state.rolesTable.selected.map(item => item.roleId)
    })
  },
  methods: {
    onRemoveConfirmed() {
      console.log("confirmed delete", this.selected);
      RolesService.DeleteRole(this.selected).then(response => {
        if (response.isValid) {
          EmitSuccess(this.$t("roles.deleted"));
          this.$store.dispatch("SET_ROLESTABLE_SELECTED", []);
          this.$refs.rolesTable.getRoles();
        }
      });
    },
    onCreateClick() {
      this.$router.push({ name: "createRole" });
    }
  }
};
</script>
