<template>
  <v-flex>
    <v-layout justify-end row wrap>
      <v-flex xs12 md3>
        <v-text-field
          v-model="search"
          :label="$t('roles.search')"
          single-line
          hide-details
          clearable
          prepend-inner-icon="fas fa-shield-alt fa-sm"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-data-table
          v-model="selected"
          :search="search"
          :custom-filter="customFilter"
          :loading="loading"
          :headers="headers"
          :items="items"
          item-key="roleId"
          :items-per-page="5"
          show-select
        >
          <template v-slot:header.data-table-select></template>
          <v-progress-linear slot="progress" indeterminate></v-progress-linear>
          <template v-slot:item.options="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  text
                  icon
                  :to="{
                    name: 'role',
                    params: { roleId: item.roleId }
                  }"
                  v-on="on"
                >
                  <fa-icon icon="pencil-alt"></fa-icon>
                </v-btn>
              </template>
              <span>{{ $t("global.edit") }}</span>
            </v-tooltip>
          </template>
          <template slot="pageText" slot-scope="props"></template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-flex>
</template>
<script>
import RolesService from "../services/RolesService.js";
import filters from "../mixins/filters.js";
import { Header } from "../common/Common.js";
import { mapState } from "vuex";
export default {
  name: "RolesTable",
  mixins: [filters],
  data() {
    return {
      search: "",
      loading: true,
      headers: [
        new Header(this.$t("roles.name"), "roleName"),
        new Header(this.$t("roles.restrictions"), "restrictions"),
        new Header("", "options", 50)
      ],
      items: []
    };
  },
  mounted() {
    this.getRoles();
  },
  computed: {
    ...mapState({ selectedState: state => state.rolesTable.selected }),
    selected: {
      get: function() {
        return this.selectedState;
      },
      set: function(newValue) {
        this.$store.dispatch("SET_ROLESTABLE_SELECTED", newValue);
      }
    }
  },
  methods: {
    getRoles() {
      this.loading = true;
      return RolesService.GetRoles().then(data => {
        this.items = data;
        this.loading = false;
      });
    },
    // customFilter(items, search, filter) {
    //   return items.filter(row =>
    //     filter(row["roleName"], search.toString().toLowerCase())
    //   );
    // }
    customFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    }
  }
};
</script>
